

// // import "../. /style.css";
// import { useState } from "react";
// import {QrReader} from "react-qr-reader";

// const App = () => {
//   const [selected, setSelected] = useState("environment");
//   const [startScan, setStartScan] = useState(false);
//   const [loadingScan, setLoadingScan] = useState(false);
//   const [data, setData] = useState("");

//   const handleScan = async (scanData) => {
//     setLoadingScan(true);
//     console.log(`loaded data data`, scanData);
//     if (scanData && scanData !== "") {
//       console.log(`loaded >>>`, scanData);
//       setData(scanData);
//       setStartScan(false);
//       setLoadingScan(false);
//       // setPrecScan(scanData);
//     }
//   };
//   const handleError = (err) => {
//     console.error(err);
//   };
//   return (
//     <div className="App">
//       <h1>Hello CodeSandbox</h1>
//       <h2>
//         Last Scan:
//         {selected}
//       </h2>

//       <button
//         onClick={() => {
//           setStartScan(!startScan);
//         }}
//       >
//         {startScan ? "Stop Scan" : "Start Scan"}
//       </button>
//       {startScan && (
//         <>
//           <select onChange={(e) => setSelected(e.target.value)}>
//             <option value={"environment"}>Back Camera</option>
//             <option value={"user"}>Front Camera</option>
//           </select>
//           <QrReader
//             facingMode={selected}
//             delay={1000}
//             onError={handleError}
//             onScan={handleScan}
//             // chooseDeviceId={()=>selected}
//             style={{ width: "300px" }}
//           />
//         </>
//       )}
//       {loadingScan && <p>Loading</p>}
//       {data !== "" && <p>{data}</p>}
//     </div>
//   );
// };

// export default App;

// import React, { useRef, useState } from 'react';
// import { BrowserQRCodeReader } from '@zxing/library';

// const QRScanner = () => {
//   const videoRef = useRef(null);
//   const codeReader = useRef(new BrowserQRCodeReader());
//   const [scanning, setScanning] = useState(false);

//   const startScanner = () => {
//     setScanning(true);

//     // Accessing the camera
//     navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
//       .then((stream) => {
//         videoRef.current.srcObject = stream;
//         codeReader.current.decodeFromVideoDevice(undefined, videoRef.current, (result, error) => {
//           if (result) {
//             console.log('QR code data:', result.getText());
//           }
//           if (error) {
//             console.error('QR code scanning error:', error);
//           }
//         });
//       })
//       .catch((error) => {
//         console.error('Error accessing camera:', error);
//       });
//   };

//   const stopScanner = () => {
//     setScanning(false);
//     codeReader.current.reset();
//     const stream = videoRef.current.srcObject;
//     if (stream) {
//       const tracks = stream.getTracks();
//       tracks.forEach(track => track.stop());
//       videoRef.current.srcObject = null;
//     }
//   };

//   return (
//     <div>
//       {scanning ? (
//         <div>
//           <video ref={videoRef} autoPlay playsInline />
//           <button onClick={stopScanner}>Stop Scanner</button>
//         </div>
//       ) : (
//         <button onClick={startScanner}>Start Scanner</button>
//       )}
//     </div>
//   );
// };

// export default QRScanner;



import React, { useRef, useState, useEffect } from 'react';
import { BrowserQRCodeReader } from '@zxing/library';

const QRScanner = () => {
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserQRCodeReader());
  const [qrCodeValue, setQRCodeValue] = useState('');
  const [scanning, setScanning] = useState(true); // Initially start scanning

  const startScanner = () => {
    setScanning(true);
  };

  const stopScanner = () => {
    setScanning(false);
  };

  useEffect(() => {
    if (scanning) {
      // Accessing the camera
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          codeReader.current.decodeFromVideoDevice(undefined, videoRef.current, (result, error) => {
            if (result) {
              setQRCodeValue(result.getText());
              // Stop scanning after getting a QR code
              stopScanner();
            }
            if (error) {
              console.error('QR code scanning error:', error);
            }
          });
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    }
  }, [scanning]);

  return (
    <div>
      {scanning ? (
        <div>
          <video ref={videoRef} autoPlay playsInline />
          <button onClick={stopScanner}>Stop Scanner</button>
        </div>
      ) : (
        <div>
          <p>QR Code Value: {qrCodeValue}</p>
          <button onClick={startScanner}>Start Scanner</button>
        </div>
      )}
    </div>
  );
};

export default QRScanner;

