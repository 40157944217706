import React, { useRef, useState, useEffect } from 'react';
import { BrowserQRCodeReader } from '@zxing/library';

const QRScanner = () => {
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserQRCodeReader());
  const [qrCodeValue, setQRCodeValue] = useState('');
  const [scanning, setScanning] = useState(true); // Initially start scanning

  const startScanner = () => {
    setScanning(true);
  };

  const stopScanner = () => {
    setScanning(false);
  };

  useEffect(() => {
    if (scanning) {
      // Accessing the camera
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          codeReader.current.decodeFromVideoDevice(undefined, videoRef.current, (result, error) => {
            if (result) {
              setQRCodeValue(result.getText());
              // Stop scanning after getting a QR code
              stopScanner();
            }
            if (error) {
              console.error('QR code scanning error:', error);
            }
          });
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    }
  }, [scanning]);

  return (
    <div>
      {scanning ? (
        <div>
          <video ref={videoRef} autoPlay playsInline />
          <button onClick={stopScanner}>Stop Scanner</button>
        </div>
      ) : (
        <div>
          <p>QR Code Value: {qrCodeValue}</p>
          <button onClick={startScanner}>Start Scanner</button>
        </div>
      )}
    </div>
  );
};

export default QRScanner;