import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '../pages/home'
import Scanner from '../pages/scanner'

const RoutePage = () => {
  return (
 <BrowserRouter>
    <Routes>
        {/* <Route path='/' Component={  }/> */}
        <Route path='/' element={<App/>}/>
        <Route path='/scanner' element={<Scanner/>}/>
    </Routes>
 </BrowserRouter>
  )
}

export default RoutePage